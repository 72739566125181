import axios from 'axios';

let getUsersToken;
export const getUsers = async (page, perPage, sortBy, filters, globalFilter) => {
  try {
    if (typeof getUsersToken !== typeof undefined) {
      getUsersToken.cancel('Operation canceled due to new request.');
    }

    getUsersToken = axios.CancelToken.source();

    return axios.get(`users`, {
      cancelToken: getUsersToken.token,
      params: {
        page,
        perPage,
        globalFilter,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export const createUser = async values => {
  try {
    const response = await axios.post('/users', { ...values });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async userId => {
  return axios.put(`/users/${userId}/delete`, { userId });
};

export const editUser = async values => {
  return axios.put(`/users/${values.userId}`, { ...values });
};

export const assignUsersToOrganizations = async (users, organizations) => {
  return axios.post('/users/assign-organizations', { users, organizations });
};

export const removeAccessToOrganization = async (organizationsIds, userId) => {
  return axios.post('/users/remove-access', { organizationsIds, userId });
};

export const activateUser = async userId => {
  return axios.put(`/users/${userId}/active`, { userId, active: true });
};

export const deactivateUser = async userId => {
  return axios.put(`/users/${userId}/active`, { userId, active: false });
};

export default { getUsers, createUser };
