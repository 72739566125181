import axios from 'axios';

let getConnectionsToken;
export const getConnections = async (page, perPage, sortBy, filters, globalFilter) => {
  try {
    if (typeof getConnectionsToken !== typeof undefined) {
      getConnectionsToken.cancel('Operation canceled due to new request.');
    }

    getConnectionsToken = axios.CancelToken.source();

    return axios.get(`connections`, {
      cancelToken: getConnectionsToken.token,
      params: {
        page,
        perPage,
        globalFilter,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

let getConnectionLogsToken;
export const getConnectionLogs = async (connectionId, page, perPage, sortBy, filters, globalFilter, useToken: true) => {
  try {
    if (useToken && typeof getConnectionLogsToken !== typeof undefined) {
      getConnectionLogsToken.cancel('Operation canceled due to new request.');
    }

    if (useToken) {
      getConnectionLogsToken = axios.CancelToken.source();
    }

    return axios.get(`${connectionId}/log-entries`, {
      cancelToken: useToken ? getConnectionLogsToken.token : null,
      params: {
        page,
        perPage,
        ...filters,
        globalDocumentFilter: globalFilter,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

let getConnectionTasksToken;
export const getConnectionTasks = async (connectionId, page, perPage, sortBy, filters, globalFilter) => {
  try {
    if (typeof getConnectionTasksToken !== typeof undefined) {
      getConnectionTasksToken.cancel('Operation canceled due to new request.');
    }

    getConnectionTasksToken = axios.CancelToken.source();

    return axios.get(`${connectionId}/tasks`, {
      cancelToken: getConnectionTasksToken.token,
      params: {
        page,
        perPage,
        globalFilter,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

let getConnectionSyncItemsToken;
export const getConnectionSyncItems = async (connectionId, page, perPage, sortBy, filters, globalFilter) => {
  try {
    if (typeof getConnectionSyncItemsToken !== typeof undefined) {
      getConnectionSyncItemsToken.cancel('Operation canceled due to new request.');
    }

    getConnectionSyncItemsToken = axios.CancelToken.source();

    return axios.get(`/connections/${connectionId}/sync-item`, {
      cancelToken: getConnectionSyncItemsToken.token,
      params: {
        page,
        perPage,
        globalFilter,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

let getConnectionSyncElementsToken;
export const getConnectionSyncElements = async (
  connectionId,
  syncElementName,
  page,
  perPage,
  syncStatus,
  syncKey,
  useToken = true,
) => {
  try {
    if (useToken && typeof getConnectionSyncElementsToken !== typeof undefined) {
      getConnectionSyncElementsToken.cancel('Operation canceled due to new request.');
    }

    if (useToken) {
      getConnectionSyncElementsToken = axios.CancelToken.source();
    }

    return axios.get(`/connections/${connectionId}/sync-element`, {
      cancelToken: useToken ? getConnectionSyncElementsToken.token : null,
      params: {
        syncElementName,
        page,
        perPage,
        syncStatus,
        syncKey,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export const getConnectionCurlCollections = async (connectionId, page, perPage, curlStatus, taskId = null) => {
  let appendage = '';
  if (curlStatus === 'success') {
    appendage = '&response.statusCode=200,201,202,203';
  } else if (curlStatus === 'failed') {
    appendage = '&response.statusCode=401,402,403,404,500,501,502,503,504,301,302,303';
  }

  if (taskId) {
    appendage = `&taskId=${taskId}`;
  }

  return axios.get(`/request-and-responses?connectionId=${connectionId}${appendage}`, {
    params: {
      page,
      perPage,
    },
  });
};

export const getConnectionCurlCollection = async (connectionId, curlId) => {
  return axios.get(`/request-and-responses/${curlId}?connectionId=${connectionId}`);
};

export const resetSyncElementSyncStatus = async (connectionId, syncElementName, syncElementKey) => {
  return axios.post(`/connections/${connectionId}/reset-sync-element`, {
    connectionId,
    syncElementName,
    syncElementKey,
  });
};

export const resetSyncElementFromSource = async (connectionId, syncElementName, syncElementKey) => {
  return axios.post(`/connections/${connectionId}/reset-sync-element-from-source`, {
    connectionId,
    syncElementName,
    syncElementKey,
  });
};

export const ignoreSyncElement = async (connectionId, syncElementName, syncElementKey) => {
  return axios.post(`/connections/${connectionId}/ignore-sync-element`, {
    connectionId,
    syncElementName,
    syncElementKey,
  });
};

let getConnectionSyncStatusToken;
export const getConnectionSyncStatusReport = async connectionId => {
  try {
    if (typeof getConnectionSyncStatusToken !== typeof undefined) {
      getConnectionSyncStatusToken.cancel('Operation canceled due to new request.');
    }

    getConnectionSyncStatusToken = axios.CancelToken.source();

    return axios.get(`/connections/${connectionId}/report/sync-status`, {
      cancelToken: getConnectionSyncStatusToken.token,
      params: {},
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

let getConnectionModulesToken;
export const getConnectionModules = async (connectionId, page, perPage, sortBy, filters, globalFilter) => {
  try {
    if (typeof getConnectionModulesToken !== typeof undefined) {
      getConnectionModulesToken.cancel('Operation canceled due to new request.');
    }

    getConnectionModulesToken = axios.CancelToken.source();

    return axios.get(`${connectionId}/modules`, {
      cancelToken: getConnectionModulesToken.token,
      params: {
        page,
        perPage,
        globalFilter,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

export const getLogsCount = async connectionsIds => {
  return axios.post('connections/logscount/', { connectionsIds });
};

export const getEventsCount = async connectionsIds => {
  return axios.post('connections/eventscount/', { connectionsIds });
};

export const getConnection = async connectionId => {
  return axios.get(`/connections/${connectionId}`).catch(response => {
    console.log(response);
  });
};

export const createConnection = async values => {
  return axios.post('/connection', { ...values });
};

export const clearConnectionTasks = async (connectionId, force) => {
  return axios.post(`/connections/${connectionId}/clear-tasks`, { connectionId, force });
};

export const clearConnectionLogs = async (connectionId, old) => {
  return axios.post(`/connections/${connectionId}/clear-logs`, { connectionId, old });
};

export const clearSelectedConnectionLogs = async (connectionId, logEntryIds) => {
  return axios.post(`/connections/${connectionId}/clear-selected-logs`, { connectionId, logEntryIds });
};

export const updateAllConnectionLogsStatus = async (connectionId, status) => {
  return axios.post(`/connections/${connectionId}/change-logs-status`, { connectionId, status });
};

export const updateSelectedConnectionLogsStatus = async (connectionId, status, logEntryIds) => {
  return axios.post(`/connections/${connectionId}/change-selected-logs-status`, {
    connectionId,
    status,
    logEntryIds,
  });
};

export const runConnectionModule = async values => {
  return axios.post(`/connection/${values.connectionId}/module/run`, { ...values });
};

export const purgeConnection = async values => {
  return axios.post(`/connection/${values.connectionId}/purge`, { ...values });
};

export const forceRunConnection = async values => {
  return axios.post(`/connections/${values.connectionId}/force-run`, { ...values });
};

export const resetSyncStatus = async values => {
  return axios.post(`/connections/${values.connectionId}/reset-sync-status`, { ...values });
};

export const changeConnectionModule = async values => {
  return axios.post(`/connection/${values.connectionId}/settings`, { ...values });
};

export const changeNotes = async values => {
  return axios.post(`/connection/${values.connectionId}/update-notes`, { ...values });
};

export const updateConnection = async values => {
  return axios.put(`connections/${values.connectionId}`, { ...values });
};

export const deleteConnection = async (connectionId, reason) => {
  return axios.put(`connections/${connectionId}/delete`, { connectionId, reason });
};

export const activateConnection = async connectionId => {
  return axios.put(`connections/${connectionId}/active`, { connectionId, active: true });
};

export const deactivateConnection = async connectionId => {
  return axios.put(`connections/${connectionId}/active`, { connectionId, active: false });
};

// export const downloadMissingDetails = async connectionId => {
//   try {
//     return axios.get(`/${connectionId}/data/insurance-brydge/missing-infos/download`);
//   } catch (err) {
//     throw err;
//   }
// };

// export const checkDownload = async connectionId => {
//   try {
//     return axios.get(`/${connectionId}/data/insurance-brydge/missing-infos/check-download`);
//   } catch (err) {
//     throw err;
//   }
// };

export default { getConnections, createConnection };
