import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import { withRouter } from 'react-router-dom';
import FormikModal from '../../../../components/FormikModal';
import { actions as ConnectionsActions } from '../../Ducks/Connections.duck';
import { actions as OnboardActions } from '../../Ducks/Onboard.duck';
import { ConnectionsTable, ConnectionsToolbar } from '../../Components';
import ConnectionRunForm from '../../Components/Forms/ConnectionRunForm/ConnectionRunForm';
import { changeConnectionModule, runConnectionModule, updateConnection } from '../../Services/ConnectionService';
import OnboardingWizard from '../../Components/OnboardingWizard';
import { actions as CredentialsAction } from '../../../Credentials/Ducks/Credentials.duck';
import ChangeConnectionWizard from '../../Components/Change/ChangeConnectionWizard';
import { ConfirmDialog } from '../../../../components';
import { actions as OnboardingActions } from '../../Ducks/Onboarding.duck';
import DeleteConnectionModal from './DeleteConnectionModal';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const ConnectionManagementPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [onboardingOpen, setOnboardingOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [confirmActivationModal, setConfirmActivationModal] = useState(false);
  const [confirmDeactivationModal, setConfirmDeactivationModal] = useState(false);

  const { loading, items, page, pageSize, totalCount, selectedRow, selectedRowTenants } = useSelector(
    state => state.ConnectionsReducer,
  );

  useEffect(() => {
    dispatch(ConnectionsActions.initConnectionPage());
    dispatch(ConnectionsActions.requestData());
  }, [dispatch]);

  const handleSearch = event => {
    dispatch(ConnectionsActions.search(event.target.value));
  };

  const handlePageChange = (event, newPage) => {
    dispatch(ConnectionsActions.changePage(newPage + 1));
  };

  const handleRowsPerPageChange = event => {
    dispatch(ConnectionsActions.setPageSize(event.target.value));
  };

  const handleClickRun = selectedConnectionRow => {
    dispatch(ConnectionsActions.setSelectedRow(selectedConnectionRow));
    setModalOpen(true);
  };

  const handleClickChange = selectedConnectionRow => {
    dispatch(ConnectionsActions.setSelectedRow(selectedConnectionRow));
    dispatch(OnboardActions.resetData());
    setModal2Open(true);
  };

  const handleRunConnectionModule = async values => {
    const payload = {
      connectionId: selectedRow.connectionId,
      moduleType: values.extractionType,
      parameters: {
        date: format(values.date, 'yyyy-MM-dd'),
        tenants: values.tenants,
      },
    };

    try {
      await runConnectionModule(payload);
      setModalOpen(false);
    } catch (e) {
      console.log(e);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleChangeConnectionSettings = async () => {
    const payload = {
      connectionId: selectedRow.connectionId,
      packageType: selectedRow.packages[0].packageType,
      settings: { tenants: selectedRowTenants },
    };

    try {
      await changeConnectionModule(payload);
      setModal2Open(false);
      dispatch(ConnectionsActions.requestData());
    } catch (e) {
      console.log(e);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleAddTenant = async (values, form) => {
    dispatch(ConnectionsActions.addTenant2SelectedRow(values, selectedRowTenants));
  };

  // eslint-disable-next-line no-unused-vars
  const handleRemoveTenant = async (values, form) => {
    dispatch(ConnectionsActions.removeTenantFromSelectedRow(values, selectedRowTenants));
  };

  const handleOnboardingConnection = async data => {
    setOnboardingOpen(false);
    dispatch(ConnectionsActions.requestData());
    dispatch(OnboardingActions.ClearAll());
  };

  const handleClickAdd = () => {
    setOnboardingOpen(true);
    dispatch(CredentialsAction.removeOauthStatus());
  };

  const handleChangeConnection = async data => {
    await updateConnection(data);
    setModal2Open(false);
    dispatch(OnboardActions.resetData());
  };

  const handleDeleteConnection = async connection => {
    setConfirmDeleteModal(true);
    dispatch(ConnectionsActions.setSelectedRow(connection));
  };

  const handleClickActivateConnection = async connection => {
    setConfirmActivationModal(true);
    dispatch(ConnectionsActions.setSelectedRow(connection));
  };

  const handleClickDeactivateConnection = async connection => {
    setConfirmDeactivationModal(true);
    dispatch(ConnectionsActions.setSelectedRow(connection));
  };

  const handleActivateConnection = async () => {
    dispatch(ConnectionsActions.activateOneConnection());
  };

  const handleDeactivateConnection = async () => {
    dispatch(ConnectionsActions.deactivateOneConnection());
  };

  return (
    <div className={classes.root}>
      <ConnectionsToolbar handleClickAdd={handleClickAdd} handleSearch={handleSearch} />
      <div className={classes.content}>
        <ConnectionsTable
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          connections={items}
          page={page - 1}
          rowsPerPage={pageSize}
          totalCount={totalCount}
          loading={loading}
          handleClickRun={handleClickRun}
          handleClickChange={handleClickChange}
          handleClickActivate={handleClickActivateConnection}
          handleClickDeactivate={handleClickDeactivateConnection}
          handleDelete={handleDeleteConnection}
        />
      </div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Formik
          validationSchema={Yup.object().shape({
            date: Yup.date().required(),
          })}
          enableReinitialize
          initialValues={{
            date: new Date(),
            tenants: [],
            extractionType: 'xeinadin_group_start_reports_extraction',
          }}
          onSubmit={handleRunConnectionModule}
        >
          <FormikModal
            show={modalOpen}
            onHide={() => setModalOpen(false)}
            title={<FormattedMessage id="connections.run" />}
          >
            <ConnectionRunForm />
          </FormikModal>
        </Formik>
      </MuiPickersUtilsProvider>

      {modal2Open && (
        <ChangeConnectionWizard
          onClose={() => setModal2Open(false)}
          onSubmit={handleChangeConnection}
          connectionId={selectedRow.connectionId}
        />
      )}

      {onboardingOpen && (
        <OnboardingWizard
          onClose={() => {
            setOnboardingOpen(false);
            dispatch(OnboardingActions.ClearAll());
          }}
          onSubmit={handleOnboardingConnection}
        />
      )}

      {confirmDeleteModal && (
        <DeleteConnectionModal
          open={confirmDeleteModal}
          setConfirmDeleteModal={setConfirmDeleteModal}
        />
      )}

      {confirmActivationModal && (
        <ConfirmDialog
          title={<FormattedMessage id="connections.confirmActivationTitle" />}
          open={confirmActivationModal}
          setOpen={setConfirmActivationModal}
          onConfirm={handleActivateConnection}
        >
          <FormattedMessage id="connections.confirmActivationMessage" />
        </ConfirmDialog>
      )}

      {confirmDeactivationModal && (
        <ConfirmDialog
          title={<FormattedMessage id="connections.confirmDeactivationTitle" />}
          open={confirmDeactivationModal}
          setOpen={setConfirmDeactivationModal}
          onConfirm={handleDeactivateConnection}
        >
          <FormattedMessage id="connections.confirmDeactivationMessage" />
        </ConfirmDialog>
      )}
    </div>
  );
};

export default withRouter(ConnectionManagementPage);
