import { debounce, put, select, takeLatest } from 'redux-saga/effects';
import { singleObjectAction, singleObjectActions, singleObjectInitialState } from '../../App/Ducks/SingleObject.duck';
import { changeNotes, forceRunConnection, getConnection, resetSyncStatus } from '../Services/ConnectionService';
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_SUCCESS } from '../../../common/constants';
import { actions as AppActions } from '../../App/Ducks/App.duck';

export const actionTypes = {
  ResetData: '[Connection] Reset Connection',
  RequestData: '[Connection] Request Connection',
  FulfilledData: '[Connection] Fulfilled Connection',
  ForceRun: '[Connection] Force Run Connection',
  ChangeNotes: '[Connection] Change notes for Connection',
  StartSavingNotes: '[Connection] StartSavingNotes for Connection',
  FinishedSavingNotes: '[Connection] FinishedSavingNotes for Connection',
  SetPageLoaded: '[Connection] Set page loaded',
  FulfilledConnection: '[Connection] Fulfilled connection',
  ResetSyncStatus: '[Connection] Reset syncStatus',
};

const initialState = {
  ...singleObjectInitialState,
  pageLoaded: false,
  connectionAccess: false,
};

export const reducer = (state = initialState, action) => {
  const newState = singleObjectAction(actionTypes, state, action);

  switch (action.type) {
    case actionTypes.ChangeNotes:
      return { ...newState, item: { ...newState.item, notes: action.payload } };

    case actionTypes.StartSavingNotes:
      return { ...newState, savingNotes: true };

    case actionTypes.FinishedSavingNotes:
      return { ...newState, savingNotes: false };

    case actionTypes.SetPageLoaded: {
      return {
        ...state,
        pageLoaded: true,
      };
    }
    case actionTypes.FulfilledConnection: {
      return {
        ...state,
        connectionAccess: true,
      };
    }
    default:
      return newState;
  }
};

export const actions = {
  ...singleObjectActions(actionTypes),
  forceRun: payload => ({
    payload,
    type: actionTypes.ForceRun,
  }),
  resetSyncStatus: payload => ({
    payload,
    type: actionTypes.ResetSyncStatus,
  }),
  setPageLoaded: () => ({
    type: actionTypes.SetPageLoaded,
  }),
  fulfilledConnection: () => ({
    type: actionTypes.FulfilledConnection,
  }),
  changeNotes: payload => ({
    payload,
    type: actionTypes.ChangeNotes,
  }),
  startSavingNotes: () => ({
    type: actionTypes.StartSavingNotes,
  }),
  finishedSavingNotes: () => ({
    type: actionTypes.FinishedSavingNotes,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.RequestData, function* requestConnectionSaga(action) {
    try {
      const response = yield getConnection(action.itemId);
      yield put(actions.setPageLoaded());
      if (response) {
        yield put(actions.fulfilledData(response.data));
        yield put(actions.fulfilledConnection());
      } else {
        yield put(actions.resetData());
      }
    } catch (error) {
      const severity = MESSAGE_SEVERITY_ERROR;
      const details = {
        message: 'getConnection',
        severity,
      };
      yield put(AppActions.displayMessage(details));
    }
  });

  yield debounce(500, actionTypes.ChangeNotes, function* reloadData({ payload }) {
    yield put(actions.startSavingNotes());

    const connectionId = yield select(state => {
      return state.ConnectionReducer.item.connectionId;
    });

    yield changeNotes({ connectionId, notes: payload });

    yield put(actions.finishedSavingNotes());
  });

  function* forceRun(action) {
    let severity = MESSAGE_SEVERITY_SUCCESS;
    try {
      yield forceRunConnection(action.payload);
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
    }
    const details = {
      message: 'connection.forceRun',
      severity,
    };
    yield put(AppActions.displayMessage(details));
  }

  yield takeLatest(actionTypes.ForceRun, forceRun);

  yield takeLatest(actionTypes.ResetSyncStatus, function* resetSyncStatusSaga(action) {
    let severity = MESSAGE_SEVERITY_SUCCESS;
    try {
      yield resetSyncStatus(action.payload);
    } catch (error) {
      severity = MESSAGE_SEVERITY_ERROR;
    }
    const details = {
      message: 'resetSyncStatus',
      severity,
    };
    yield put(AppActions.displayMessage(details));
  });
}
