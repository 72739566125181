import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Formik } from 'formik';
import { v4 } from 'uuid';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { actions as CredentialsActions } from '../../Ducks/Credentials.duck';
import { CredentialsTable, CredentialsToolbar } from '../../Components';
import CredentialConnectionsModal from '../../Components/CredentialConnectionsModal/CredentialConnectionsModal';
import FormikModal from '../../../../components/FormikModal';
import { normalizeErrors } from '../../../../utils/dataAccess';
import { createCredential, editCredential } from '../../Services/CredentialService';
import { getAvailablePackages } from '../../../Connections/Services/OnboardingService';
import CreateCredentialForm from '../../Forms/CreateCredentialForm';
import { CREDENTIAL_TYPE_OAUTH } from '../../../Connections/Components/OnboardingWizard/constants';
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_SUCCESS } from '../../../../common/constants';
import { actions as OrganizationsActions } from '../../../Organizations/Ducks/Organizations.duck';
import { actions as AppActions } from '../../../App/Ducks/App.duck';
import { ConfirmDialog } from '../../../../components';
import { FieldAdapter } from '../../../../common/YupValidations/FieldAdapter';
import { createValidationByKeys } from '../../../../common/YupValidations';
import UpdateCredentialForm from '../../Forms/UpdateCredentialForm';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const CredentialsManagementPage = ({ history }) => {
  const defaultValues = { credentialId: v4(), package: { packageType: '' }, organizationId: '' };
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState(defaultValues);
  const [availablePackages, setAvailablePackages] = useState([]);
  const {
    loading,
    items,
    page,
    pageSize,
    totalCount,
    credential,
    packageType,
    credentialType,
    credentialDetails,
    loadedCredential,
  } = useSelector(state => state.CredentialsReducer);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [newCredentialForm, setNewCredentialForm] = useState({});
  const [editCredentialModalOpen, setEditCredentialModalOpen] = useState(false);
  const [editCredentialInitialValues, setEditCredentialInitialValues] = useState({});
  const [modalLoading, setModalLoading] = useState(false);
  const [updateValidationSchema, setUpdateValidationSchema] = useState(Yup.object().shape({}));
  const [createValidationSchema, setCreateValidationSchema] = useState(Yup.object().shape({}));
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

  const handleHideDetails = () => {
    setDetailsVisible(false);
    dispatch(CredentialsActions.setCredentialId(null));
  };

  useEffect(() => {
    dispatch(CredentialsActions.initializeCredentialsState());
    dispatch(CredentialsActions.requestData());
  }, [dispatch]);

  const loadAvailablePackages = async () => {
    const response = await getAvailablePackages([], true);
    setAvailablePackages(response.data['hydra:member']);
  };

  useEffect(() => {
    loadAvailablePackages();
  }, []);

  const handleCreateCredential = async (values, form) => {
    const { credentialId } = values;

    if (newCredentialForm[packageType].credentialType === CREDENTIAL_TYPE_OAUTH) {
      values.package.credentialRequest = {
        ...values.package.credential,
        successUrl: `${window.location.origin}/credential/oauth/success/${credentialId}`,
        failureUrl: `${window.location.origin}/credential/oauth/error/${credentialId}`,
      };
    }

    try {
      await createCredential(values);
      dispatch(CredentialsActions.requestData());
      setModalOpen(false);
    } catch (e) {
      if (e.response && (e.response.status === 400 || e.response.status === 422)) {
        const errors = normalizeErrors(e.response.data);

        if (typeof errors === 'string') {
          form.setStatus(errors);
        }

        if (form) {
          Object.keys(errors).forEach(path => {
            form.setFieldError(path, errors[path]);
          });
        }

        form.setSubmitting(false);

        return;
      }

      throw e;
    }
    setCreateValidationSchema(Yup.object().shape({}));
    setNewCredentialForm({});
    dispatch(CredentialsActions.clearAll());
    form.resetForm();
  };

  const handleClickEdit = oldCredential => {
    dispatch(CredentialsActions.requestCredential(oldCredential));
    setEditCredentialModalOpen(true);
  };

  const handleSelectUpdatePackage = () => {
    dispatch(CredentialsActions.requestNewCredentialDetails(packageType));
  };

  useEffect(() => {
    if (credential) {
      setEditCredentialInitialValues({
        ...credential.data.extraData,
        ...credential.data,
        organizationName: credential.data.organization.organizationName,
      });
      if (packageType) handleSelectUpdatePackage();
    }
    // eslint-disable-next-line
  }, [credential]);

  useEffect(() => {
    if (credentialDetails && credentialType && modalOpen) {
      if (newCredentialForm.hasOwnProperty(packageType)) {
        setModalLoading(false);
        return;
      }
      setNewCredentialForm(prevState => {
        const currentState = Object.assign({}, prevState);
        currentState[packageType] = credentialDetails[credentialType];
        return currentState;
      });
      setModalLoading(false);
    }
    // eslint-disable-next-line
  }, [credentialDetails, credentialType]);

  const handleValues = values => {
    return {
      credentialId: values.credentialId,
      package: {
        packageType: values.packageType,
        credentialId: values.credentialId,
        credentialType: values.credentialType,
        credential: { ...values },
      },
    };
  };

  const handleSelectPackage = newPackageType => {
    dispatch(CredentialsActions.setSelectedCredentialType(null));
    dispatch(CredentialsActions.fulfilledNewCredentialDetails(null));

    dispatch(CredentialsActions.setSelectedPackage(newPackageType));
    dispatch(CredentialsActions.requestNewCredentialDetails(newPackageType));
    setModalLoading(true);
  };

  const handleSelectCredentialType = newCredentialType => {
    dispatch(CredentialsActions.setSelectedCredentialType(newCredentialType));
  };

  const handleEditCredential = async (values, form) => {
    let messageSeverity = MESSAGE_SEVERITY_SUCCESS;
    values = handleValues(values);
    try {
      await editCredential(values);
      setEditCredentialModalOpen(false);
      dispatch(OrganizationsActions.requestData());
    } catch (e) {
      if (e.response && (e.response.status === 400 || e.response.status === 422)) {
        const errors = normalizeErrors(e.response.data);

        if (typeof errors === 'string') {
          form.setStatus(errors);
        }

        if (form) {
          Object.keys(errors).forEach(path => {
            const fieldName = path.split('.').pop();
            form.setFieldError(fieldName, errors[path]);
          });
        }

        form.setSubmitting(false);

        return;
      }
      messageSeverity = MESSAGE_SEVERITY_ERROR;

      throw e;
    }
    setUpdateValidationSchema(Yup.object().shape({}));
    dispatch(CredentialsActions.clearAll());
    dispatch(
      AppActions.displayMessage({
        message: 'editCredential',
        severity: messageSeverity,
      }),
    );
  };

  const handleSearch = event => {
    dispatch(CredentialsActions.search(event.target.value));
  };

  const handlePageChange = (event, newPage) => {
    dispatch(CredentialsActions.changePage(newPage + 1));
  };

  const handleRowsPerPageChange = event => {
    dispatch(CredentialsActions.setPageSize(event.target.value));
  };
  const handleShowDetails = credentialId => {
    dispatch(CredentialsActions.setCredentialId(credentialId));
    setDetailsVisible(true);
  };

  const handleDeleteCredential = async credentialToDelete => {
    setConfirmDeleteModal(true);
    dispatch(CredentialsActions.setSelectedRow(credentialToDelete));
  };

  const handleDelete = async () => {
    dispatch(CredentialsActions.deleteOneCredential());
  };

  useEffect(() => {
    if (!editCredentialModalOpen && initialValues) {
      setModalLoading(true);
      let initialSchema = Yup.object().shape({});
      const organizationIdValidation = Yup.object({
        organizationId: Yup.string().required(),
      });
      initialSchema = initialSchema.concat(organizationIdValidation);
      const packageTypeValidation = Yup.object({
        package: Yup.object({
          packageType: Yup.string().required(),
        }),
      });
      initialSchema = initialSchema.concat(packageTypeValidation);
      if (packageType && credentialType && credentialDetails) {
        credentialDetails[credentialType].fields.forEach(field => {
          const fieldValidation = FieldAdapter(field).getValidation(field);
          const objectValidation = createValidationByKeys([field.fieldName, 'credential', 'package'], fieldValidation);
          initialSchema = initialSchema.concat(objectValidation);
        });
      }
      setCreateValidationSchema(initialSchema);
      setModalLoading(false);
    }
    // eslint-disable-next-line
  }, [credentialDetails, credentialType]);

  return (
    <div className={classes.root}>
      <CredentialsToolbar
        handleClickAdd={() => {
          setModalOpen(true);
          setInitialValues(defaultValues);
          setCreateValidationSchema(createValidationSchema);
        }}
        handleSearch={handleSearch}
      />
      <div className={classes.content}>
        <CredentialsTable
          handleShowDetails={handleShowDetails}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          handleClickChange={handleClickEdit}
          handleDelete={handleDeleteCredential}
          credentials={items}
          page={page - 1}
          rowsPerPage={pageSize}
          totalCount={totalCount}
          loading={loading}
        />
        <Formik
          initialValues={initialValues}
          onSubmit={handleCreateCredential}
          validationSchema={createValidationSchema}
        >
          <FormikModal
            show={modalOpen}
            onHide={() => {
              setModalOpen(false);
              dispatch(CredentialsActions.clearAll());
            }}
            title={<FormattedMessage id="credentials.add" />}
          >
            <CreateCredentialForm
              availablePackages={availablePackages}
              onSelectPackage={handleSelectPackage}
              onSelectCredentialType={handleSelectCredentialType}
              newCredentialForm={newCredentialForm}
              loading={modalLoading}
              selectedPackageType={packageType}
              credentialDetails={credentialDetails}
              selectedCredentialType={credentialType}
            />
          </FormikModal>
        </Formik>
      </div>
      {confirmDeleteModal && (
        <ConfirmDialog
          title={<FormattedMessage id="credentials.confirmDeleteTitle" />}
          open={confirmDeleteModal}
          setOpen={setConfirmDeleteModal}
          onConfirm={handleDelete}
        >
          <FormattedMessage id="credentials.confirmDeleteMessage" />
        </ConfirmDialog>
      )}
      {editCredentialModalOpen && credentialDetails && credential && (
        <Formik
          enableReinitialize
          initialValues={editCredentialInitialValues}
          onSubmit={handleEditCredential}
          validationSchema={updateValidationSchema}
        >
          <FormikModal
            show={editCredentialModalOpen}
            onHide={() => {
              setEditCredentialModalOpen(false);
              setUpdateValidationSchema(Yup.object().shape({}));
              dispatch(CredentialsActions.clearAll());
            }}
            title={<FormattedMessage id="credentials.update" />}
          >
            <UpdateCredentialForm
              initialValues={editCredentialInitialValues}
              details={credentialDetails[credential.data.credentialType].fields}
              selectedPackageType={packageType}
            />
          </FormikModal>
        </Formik>
      )}
      <CredentialConnectionsModal show={detailsVisible} hide={handleHideDetails} history={history} />
    </div>
  );
};

export default CredentialsManagementPage;
