import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, without } from 'lodash';
import { v4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import OnboardingPackageSelectionForm from '../Forms/OnboardingPackageSelectionForm/OnboardingPackageSelectionForm';
import {
  getAvailablePackages,
  getOrganizations,
  getResellers,
  onboardNewOrganization,
} from '../../Services/OnboardingService';
import { NEW_ORGANIZATION_VALUE } from './constants';
import { normalizeErrors } from '../../../../utils/dataAccess';
import { actions as OnboardingActions } from '../../Ducks/Onboarding.duck';
import { getOrganization } from '../../../Organizations/Services/OrganizationService';
import { useIntl } from 'react-intl';

const OnboardingPackageSelectionStep = ({ formRef, onSubmit, initialValues }) => {
  const [isLoadingResellers, setIsLoadingResellers] = useState(true);
  const [isLoadingOrganizations, setIsLoadingOrganizations] = useState(false);
  const [isLoadingPackages, setIsLoadingPackages] = useState(true);
  const [isLoadingValidations, setIsLoadingValidations] = useState(true);

  const [resellers, setResellers] = useState([]);
  const [selectedReseller, setSelectedReseller] = useState(null);
  const [connectionName, setConnectionName] = useState(
    initialValues.connectionName ? initialValues.connectionName : '',
  );
  const [organizations, setOrganizations] = useState(null);
  const [connectionNameChunks, setConnectionNameChunks] = useState([]);
  const [validationSchema, setValidationSchema] = useState(Yup.object().shape({}));
  const [newOrganizationForm, setNewOrganizationForm] = useState(
    initialValues.organizationId === NEW_ORGANIZATION_VALUE,
  );
  const [availablePackages, setAvailablePackages] = useState(null);
  const [selectedPackages, setSelectedPackages] = useState(initialValues.packages ? initialValues.packages : []);
  const dispatch = useDispatch();

  const { user } = useSelector(state => state.AppReducer);

  const intl = useIntl();
  const loading = isLoadingResellers || isLoadingOrganizations || isLoadingPackages || isLoadingValidations;

  const loadResellers = async () => {
    setIsLoadingResellers(true);

    const response = await getResellers();

    const resellersArray = response.data['hydra:member'];

    user.accessToOrganizations.forEach(orgAccess => {
      const { reseller } = orgAccess.organization;

      if (!reseller) {
        return;
      }

      const indexOfExistingReseller = resellersArray.findIndex(
        organization => organization.organizationId === reseller.organizationId,
      );

      if (indexOfExistingReseller === -1) {
        resellersArray.push(reseller);
      }
    });

    setResellers(resellersArray);
    setIsLoadingResellers(false);
  };

  const loadAvailablePackages = async packages => {
    setIsLoadingPackages(true);
    const response = await getAvailablePackages(packages);
    setAvailablePackages(response.data['hydra:member']);
    setIsLoadingPackages(false);
  };

  const loadOrganizations = async resellerId => {
    setIsLoadingOrganizations(true);
    const response = await getOrganizations(resellerId);
    setOrganizations(response.data['hydra:member']);
    setIsLoadingOrganizations(false);
  };

  const setValidations = () => {
    setIsLoadingValidations(true);
    let initialSchema = Yup.object().shape({});
    const connectionTagValidation = Yup.object({
      connectionTag: Yup.string()
        .trim()
        .strict()
        .required(),
    });
    initialSchema = initialSchema.concat(connectionTagValidation);
    const resellerValidation = Yup.object({
      resellerId: Yup.string()
        .ensure()
        .required('The field is required'),
    });
    initialSchema = initialSchema.concat(resellerValidation);
    const organizationIdValidation = Yup.object({
      organizationId: Yup.string()
        .ensure()
        .required(),
      organizationName: Yup.string()
        .trim()
        .strict()
        .when('organizationId', {
          is: NEW_ORGANIZATION_VALUE,
          then: Yup.string().required(),
          otherwise: Yup.string(),
        }),
      organizationCode: Yup.string().when('organizationId', {
        is: NEW_ORGANIZATION_VALUE,
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
    });
    initialSchema = initialSchema.concat(organizationIdValidation);
    const packageTypeValidation = Yup.object({
      hidden: Yup.number(),
      packages: Yup.string()
        .required('Please select at least two packages')
        .when('hidden', {
          is: x => x < 2,
          then: Yup.string()
            .required('Please select at least two packages')
            .matches(/(' {2}')/, { message: 'Please select at least two packages' }),
        }),
    });
    initialSchema = initialSchema.concat(packageTypeValidation);
    const acknowledgeCostsValidation = Yup.object({
      acknowledgeCosts: Yup.bool().oneOf([true], intl.formatMessage({id: "connection.acknowledgeAdditionalCosts.error"})),
    });
    initialSchema = initialSchema.concat(acknowledgeCostsValidation);
    const agreeToSValidation = Yup.object({
      agreeTos: Yup.bool().oneOf([true], intl.formatMessage({id: "connection.agreeToS.error"})),
    });
    initialSchema = initialSchema.concat(agreeToSValidation);
    setValidationSchema(initialSchema);
    setIsLoadingValidations(false);
  };

  const handleUpdateConnectionName = (value, position) => {
    setConnectionNameChunks(prevChunks => {
      if (prevChunks[position] === value) {
        return prevChunks;
      }

      const newChunks = [...prevChunks];
      newChunks[position] = value;

      const newConnectionName = newChunks.join('') === '' ? '' : newChunks.join(' - ');
      setConnectionName(newConnectionName);

      return newChunks;
    });
  };

  useEffect(() => {
    setValidations();
    if (!isEmpty(initialValues)) {
      setSelectedReseller(initialValues.resellerId);
    }
    loadResellers();
    loadAvailablePackages(selectedPackages);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedReseller) {
      loadOrganizations(selectedReseller.organizationId);
    }
  }, [selectedReseller]);

  useEffect(() => {
    loadAvailablePackages(selectedPackages);
  }, [selectedPackages]);

  const handleSelectReseller = resellerId => {
    setSelectedReseller(resellerId);
  };

  const handleUpdateTag = value => {
    handleUpdateConnectionName(value, 2);
  };

  const handleSelectPackage = (packageType, setFieldValue) => {
    setFieldValue(`hidden`, selectedPackages.length + 1);
    dispatch(OnboardingActions.SetSelectedPackage(packageType));
    setSelectedPackages([...new Set([...selectedPackages, packageType])]);

    handleUpdateConnectionName([...selectedPackages, packageType].join(' '), 1);
  };

  const handleCreateNewOrganization = value => {
    handleUpdateConnectionName(value, 0);
  };

  const handleRemoveSelectedPackage = (packageType, setFieldValue) => {
    setFieldValue(`hidden`, selectedPackages.length - 1);
    const selectedPackagesAfterRemove = without(selectedPackages, packageType);
    setSelectedPackages(selectedPackagesAfterRemove);
    dispatch(OnboardingActions.RemovePackage(packageType));

    handleUpdateConnectionName(selectedPackagesAfterRemove.join(' '), 1);
  };

  const onSelectOrganization = value => {
    if (value && value === NEW_ORGANIZATION_VALUE) {
      setNewOrganizationForm(true);
    } else if (value && value !== NEW_ORGANIZATION_VALUE) {
      handleUpdateConnectionName(value.organizationName, 0);
    } else {
      handleUpdateConnectionName('', 0);
      setNewOrganizationForm(false);
    }
  };

  const createOrganization = async (values, form) => {
    const organizationValues = {
      organizationId: v4(),
      organizationCode: values.organizationCode,
      organizationName: values.organizationName,
      resellerId: values.resellerId,
    };
    try {
      handleUpdateConnectionName(values.organizationName, 0);
      return await onboardNewOrganization(organizationValues);
    } catch (err) {
      if (err.response.status === 400) {
        const errors = normalizeErrors(err.response.data);

        if (typeof errors === 'string') {
          form.setStatus(errors);
        }

        form.setErrors(errors);
        form.setSubmitting(false);
      }
    }
  };

  const onSubmitForm = async (values, form) => {
    values.packages = selectedPackages;
    dispatch(OnboardingActions.SetSelectedReseller(selectedReseller));
    values.resellerId = selectedReseller.organizationId;
    values.connectionName = connectionName;
    if (values.organizationId.organizationId) {
      dispatch(OnboardingActions.SetSelectedOrganization(values.organizationId));
      values.organizationId = values.organizationId.organizationId;
    }
    if (values.organizationId === NEW_ORGANIZATION_VALUE) {
      const response = await createOrganization(values, form);
      values.organizationId = response.data.organizationId;
      values.organizationCode = '';
      values.organizationName = '';
      const response2 = await getOrganization(response.data.organizationId);
      dispatch(OnboardingActions.SetSelectedOrganization(response2.data));
    }
    onSubmit(values);
  };

  return (
    <OnboardingPackageSelectionForm
      formRef={formRef}
      loading={loading}
      initialValues={initialValues}
      resellers={resellers}
      organizations={organizations}
      onSelectOrganization={onSelectOrganization}
      newOrganizationForm={newOrganizationForm}
      availablePackages={availablePackages}
      selectedPackages={selectedPackages}
      onSelectReseller={handleSelectReseller}
      onSelectPackage={handleSelectPackage}
      onRemoveSelectedPackage={handleRemoveSelectedPackage}
      onUpdateTag={handleUpdateTag}
      validationSchema={validationSchema}
      connectionName={connectionName}
      onUpdateNewConnectionName={handleCreateNewOrganization}
      onSubmit={onSubmitForm}
    />
  );
};

OnboardingPackageSelectionStep.propTypes = {
  formRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default OnboardingPackageSelectionStep;
