import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import FormikModal from '../../../../components/FormikModal';
import DeleteConnectionForm from './DeleteConnectionForm';
import { actions as ConnectionsActions } from '../../Ducks/Connections.duck';
import { normalizeErrors } from '../../../../utils/dataAccess';
import { actions as AppActions } from '../../../App/Ducks/App.duck';
import { MESSAGE_SEVERITY_ERROR, MESSAGE_SEVERITY_SUCCESS } from '../../../../common/constants';

const DeleteConnectionModal = ({ open, setConfirmDeleteModal }) => {
  const [initialValues] = useState({ deleteReason: '' });
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    deleteReason: Yup.string().required(),
  });

  const handleSubmit = async (values, form) => {
    const message = 'connections.delete.message';
    try {
      dispatch(ConnectionsActions.deleteOneConnection(values.deleteReason));
      setConfirmDeleteModal(false);

      dispatch(
        AppActions.displayMessage({
          message,
          severity: MESSAGE_SEVERITY_SUCCESS,
        }),
      );
    } catch (e) {
      if (e.response && e.response.status === 422) {
        const errors = normalizeErrors(e.response.data);
        if (typeof errors === 'string') {
          form.setStatus(errors);
        }

        form.setErrors(errors);
        form.setSubmitting(false);
      }

      dispatch(
        AppActions.displayMessage({
          message,
          severity: MESSAGE_SEVERITY_ERROR,
        }),
      );
    }
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <FormikModal
        show={open}
        onHide={() => setConfirmDeleteModal(false)}
        title={<FormattedMessage id="connections.confirmDeleteTitle" />}
      >
        <FormattedMessage id="connections.deleteConnectionReasonMessage" />
        <DeleteConnectionForm />
      </FormikModal>
    </Formik>
  );
};

export default DeleteConnectionModal;
