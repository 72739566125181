import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import { isEmpty, startCase } from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Field, Form, Formik } from 'formik';
import Box from '@material-ui/core/Box';
import { TextField, Checkbox } from 'formik-material-ui';
import MuiTextField from '@material-ui/core/TextField';
import { Autocomplete } from 'formik-material-ui-lab';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';
import { NEW_ORGANIZATION_VALUE } from '../../OnboardingWizard/constants';
import FormikTextField from '../../../../../components/FormikTextField';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '35%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  flex: {
    display: 'flex',
  },
  alignContentCenter: {
    alignContent: 'center',
  },
}));

const OnboardingPackageSelectionForm = ({
  formRef,
  loading,
  initialValues,
  resellers,
  organizations,
  newOrganizationForm,
  onSelectOrganization,
  availablePackages,
  selectedPackages,
  connectionName,
  onSelectReseller,
  onSelectPackage,
  onRemoveSelectedPackage,
  onSubmit,
  onUpdateTag,
  onUpdateNewConnectionName,
  validationSchema,
}) => {
  const classes = useStyles();

  const initialFormValues = isEmpty(initialValues)
    ? {
        connectionTag: '',
        connectionName: '',
        synchronizationStartDate: null,
        resellerId: '',
        organizationId: '',
        organizationName: '',
        organizationCode: '',
        packages: '',
        hidden: 0,
        acknowledgeCosts: false,
        agreeTos: false,
      }
    : initialValues;

  const hiddenField = () => {
    return (
      <Box display="none">
        <FormikTextField label="hidden" name="hidden" fieldType="hidden" />
      </Box>
    );
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        validationSchema={validationSchema}
      >
        {({ setFieldValue, values, errors, touched }) => (
          <Form ref={formRef}>
            <Grid container className={classes.root}>
              <Grid item xs={12}>
                {loading && <LinearProgress />}
              </Grid>
              <Grid item xs={7}>
                <Box mb={2}>
                  <Field
                    name="resellerId"
                    color="secondary"
                    component={Autocomplete}
                    onChange={(event, newValue) => {
                      onSelectReseller(newValue);
                      setFieldValue('resellerId', newValue);
                    }}
                    disabled={resellers.length === 0 || !isEmpty(values.organizationId)}
                    options={resellers}
                    getOptionLabel={option =>
                      option.organizationName ? `${option.organizationName} (${option.organizationCode})` : ''
                    }
                    renderInput={params => (
                      <MuiTextField
                        {...params}
                        error={Boolean(touched.resellerId && errors.resellerId)}
                        helperText={touched.resellerId && errors.resellerId}
                        name="oneResellerId"
                        label={<FormattedMessage id="organizations.reseller" />}
                      />
                    )}
                  />
                </Box>
              </Grid>

              <Grid item xs={7}>
                <Box mb={2}>
                  <Field
                    name="organizationId"
                    color="secondary"
                    component={Autocomplete}
                    onChange={(event, newValue) => {
                      if (values.resellerId) {
                        onSelectOrganization(newValue);
                        setFieldValue('organizationId', newValue);
                      }
                    }}
                    disabled={!organizations || isEmpty(values.resellerId)}
                    options={organizations ? [NEW_ORGANIZATION_VALUE, ...organizations] : ''}
                    getOptionLabel={option =>
                      option.organizationName
                        ? `${option.organizationName} (${option.organizationCode})`
                        : option === NEW_ORGANIZATION_VALUE
                        ? 'Create new organization'
                        : ''
                    }
                    renderInput={params => (
                      <MuiTextField
                        {...params}
                        error={Boolean(touched.organizationId && errors.organizationId)}
                        helperText={touched.organizationId && errors.organizationId}
                        name="oneOrganization"
                        label={<FormattedMessage id="connections.organization" />}
                      />
                    )}
                  />
                </Box>
              </Grid>

              {newOrganizationForm && (
                <>
                  <Grid item xs={7}>
                    <Box pl={3} mb={2}>
                      <Field
                        color="secondary"
                        component={TextField}
                        name="organizationName"
                        label={<FormattedMessage id="organizations.organizationName" />}
                        inputProps={{
                          onChange: e => onUpdateNewConnectionName(e.target.value),
                        }}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={7}>
                    <Box pl={3} mb={2}>
                      <Field
                        color="secondary"
                        component={TextField}
                        name="organizationCode"
                        label={<FormattedMessage id="organizations.organizationCode" />}
                        fullWidth
                      />
                    </Box>
                  </Grid>
                </>
              )}

              <Grid item xs={7}>
                <Box mb={2}>
                  <Field
                    color="secondary"
                    variant="inline"
                    disableFuture
                    autoOk
                    component={KeyboardDatePicker}
                    format="yyyy-MM-dd"
                    name="synchronizationStartDate"
                    label={<FormattedMessage id="onboarding.packageSelection.synchronizationStartDate" />}
                    fullWidth
                  />
                </Box>
              </Grid>

              {hiddenField()}
              <Grid item xs={7}>
                <Field
                  color="secondary"
                  component={TextField}
                  inputProps={{
                    onChange: e => {
                      onSelectPackage(e.target.value, setFieldValue);
                    },
                    value: '',
                  }}
                  select
                  name="packages"
                  label={<FormattedMessage id="onboarding.packageToConnect" />}
                  disabled={!availablePackages || !availablePackages.length}
                  fullWidth
                >
                  {availablePackages ? (
                    availablePackages.map(availablePackage => (
                      <MenuItem key={availablePackage.packageType} value={availablePackage.packageType}>
                        {startCase(availablePackage.packageType.replace('-', ' '))}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem />
                  )}
                </Field>
              </Grid>

              {selectedPackages && (
                <Grid item xs={7}>
                  <List>
                    {selectedPackages.map(selectedPackage => (
                      <ListItem key={selectedPackage}>
                        <ListItemText primary={startCase(selectedPackage.replace('-', ' '))} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => onRemoveSelectedPackage(selectedPackage, setFieldValue)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              )}
              <Grid item xs={7}>
                <Box mb={2}>
                  <Field
                    color="secondary"
                    component={TextField}
                    name="connectionTag"
                    label={<FormattedMessage id="onboarding.packageSelection.connectionTag" />}
                    inputProps={{
                      onChange: e => onUpdateTag(e.target.value),
                    }}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={7}>
                <Box mb={2}>
                  <Field
                    color="secondary"
                    component={TextField}
                    name="connectionName"
                    label={<FormattedMessage id="onboarding.packageSelection.connectionName" />}
                    inputProps={{
                      value: connectionName,
                    }}
                    fullWidth
                    disabled
                  />
                </Box>
              </Grid>
              <Grid item xs={12} className={classes.flex}>
                <Field type="checkbox" component={Checkbox} name="acknowledgeCosts" id="acknowledgeCosts" color="primary" />
                <label htmlFor="acknowledgeCosts" className={classes.alignContentCenter}>
                  <Typography display="inline">
                    <FormattedMessage id="connection.acknowledgeAdditionalCosts" />
                  </Typography>
                </label>
              </Grid>
              {touched.acknowledgeCosts && errors.acknowledgeCosts && (
                <Grid item xs={12} className={classes.flex}>
                  <Typography color="error">
                    <FormattedMessage id="connection.acknowledgeAdditionalCosts.error" />
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} className={classes.flex}>
                <Field type="checkbox" component={Checkbox} name="agreeTos" id="agreeTos" color="primary" />
                <label htmlFor="agreeTos" className={classes.alignContentCenter}>
                  <Typography display="inline">
                    <FormattedMessage id="connection.agreeToS" values={{ terms: <a href="https://databrydge.nl/privacy-algemene-voorwaarden/" target="_blank"><FormattedMessage id="connection.termsAndConditionsAndPrivacyPolicy" /></a>}} />
                  </Typography>
                </label>
              </Grid>
              {touched.agreeTos && errors.agreeTos && (
                <Grid item xs={12} className={classes.flex}>
                  <Typography color="error">
                    <FormattedMessage id="connection.agreeToS.error" />
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
    </MuiPickersUtilsProvider>
  );
};

OnboardingPackageSelectionForm.propTypes = {
  formRef: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  resellers: PropTypes.array,
  organizations: PropTypes.array,
  newOrganizationForm: PropTypes.bool,
  connectionName: PropTypes.string.isRequired,
  onSelectOrganization: PropTypes.func.isRequired,
  onUpdateTag: PropTypes.func.isRequired,
  availablePackages: PropTypes.array,
  selectedPackages: PropTypes.array,
  onSelectReseller: PropTypes.func.isRequired,
  onSelectPackage: PropTypes.func.isRequired,
  onRemoveSelectedPackage: PropTypes.func.isRequired,
  onUpdateNewConnectionName: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validationSchema: PropTypes.object.isRequired,
};

export default OnboardingPackageSelectionForm;
