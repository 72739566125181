import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  textField: {
    marginTop: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
      height: 80,
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(3),
    },
  },
}));

const DeleteConnectionForm = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Field
          className={classes.textField}
          required
          color="secondary"
          component={TextField}
          name="deleteReason"
          type="string"
          variant="outlined"
          label={<FormattedMessage id="connections.deleteReason" />}
          fullWidth
          multiline
          rowsMax={3}
        />
      </Grid>
    </Grid>
  );
};

export default DeleteConnectionForm;
