import React, { useEffect, useState } from 'react';
import { CardContent, MenuItem, Select } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { getConnectionCurlCollections } from '../../Services/ConnectionService';
import CurlTable from '../CurlTable';
import { useDispatch } from 'react-redux';
import { actions as AppActions } from '../../../App/Ducks/App.duck';

const useStyles = makeStyles(() => ({
  capitalize: {
    textTransform: 'capitalize',
  },
  formControl: {
    minWidth: '100px',
  },
}));

const CurlCollection = ({ connectionId, defaultCurlStatus}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [curlCollections, setCurlCollections] = useState([]);
  const taskId = new URLSearchParams(window.location.search).get('taskId');

  const getCurlCollections = async (newPage, newPageSize, newCurlStatus) => {
    setLoading(true);
    const curlStatusFilter = newCurlStatus || 'all';
    const response = await getConnectionCurlCollections(connectionId, newPage, newPageSize, curlStatusFilter, taskId);
    setCurlCollections(response.data['hydra:member']);
    setTotalCount(response.data['hydra:totalItems']);

    setLoading(false);
  };

  useEffect(() => {
    getCurlCollections(page, pageSize, defaultCurlStatus);
  }, []);

  const handlePageChange = async (event, newPage) => {
    setPage(newPage + 1);
    await getCurlCollections(newPage + 1, pageSize, defaultCurlStatus);
  };

  const handleRowsPerPageChange = async event => {
    setPageSize(event.target.value);
    await getCurlCollections(page, event.target.value, defaultCurlStatus);
  };

  const handleCopyContentToClipboard = content => {
    navigator.clipboard.writeText(JSON.stringify(content, null, 2));
    dispatch(AppActions.displayMessage({ severity: 'success', message: 'events.copied-to-clipboard' }));
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            {!loading && (
              <CardContent>
                <CurlTable
                  curlCollections={curlCollections}
                  loading={loading}
                  paging
                  page={page - 1}
                  rowsPerPage={pageSize}
                  totalCount={totalCount}
                  handlePageChange={handlePageChange}
                  handleRowsPerPageChange={handleRowsPerPageChange}
                  handleCopyContentToClipboard={handleCopyContentToClipboard}
                />
              </CardContent>
            )}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default CurlCollection;
