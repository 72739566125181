import Grid from '@material-ui/core/Grid';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { Field, useField } from 'formik';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

const SearchableSelectFieldComponent = ({ disabled, field, path, usedValues }) => {
  const [, meta, helpers] = useField(path);
  const { setValue, setTouched } = helpers;
  const fieldOptions = field.fieldOptions.options;
  const orderDescending = 'orderDescending' in field.fieldOptions ? field.fieldOptions.orderDescending : false;
  const fieldOptionsKeys = orderDescending
    ? Object.keys(fieldOptions)
        .sort()
        .reverse()
    : Object.keys(fieldOptions);

  return (
    <Grid key={field.fieldName} item xs={12}>
      <Field name={path}>
        {({ field: formikField }) => (
          <Autocomplete
            {...formikField}
            disabled={disabled}
            getOptionLabel={option => (option ? fieldOptions[option] : '')}
            getOptionDisabled={option => usedValues.includes(option)}
            options={fieldOptionsKeys}
            color="secondary"
            value={formikField.value || ''}
            onChange={(event, newValue) => {
              setValue(newValue);
              setTouched(true);
            }}
            onBlur={() => setTouched(true)}
            renderInput={params => (
              <TextField
                {...params}
                color="secondary"
                fullWidth
                name={path}
                label={<FormattedMessage id={`onboarding.settings.${field.fieldName}`} />}
                variant="standard"
                error={meta.touched && !!meta.error}
                helperText={meta.touched && meta.error}
              />
            )}
          />
        )}
      </Field>
    </Grid>
  );
};

export default SearchableSelectFieldComponent;
